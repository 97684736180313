/**
 * @generated SignedSource<<d0ac49470250abf9c9dafdb985654a00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationOccurrenceListItem_OccurrenceFragment$data = {
  readonly datetimeRange: ReadonlyArray<string>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceListItemFragment">;
  readonly " $fragmentType": "OrganizationOccurrenceListItem_OccurrenceFragment";
};
export type OrganizationOccurrenceListItem_OccurrenceFragment$key = {
  readonly " $data"?: OrganizationOccurrenceListItem_OccurrenceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationOccurrenceListItem_OccurrenceFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationOccurrenceListItem_OccurrenceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datetimeRange",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceListItemFragment"
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};

(node as any).hash = "809827c345f934dc85791772488e38f2";

export default node;
