/**
 * @generated SignedSource<<1a9d059c4f843de4321e61aa85e219c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PurchaseTracking_ValidCheckoutFragment$data = {
  readonly cart: string;
  readonly id: string;
  readonly order: {
    readonly currency: string;
    readonly id: string;
    readonly totalPrice: number;
  } | null;
  readonly " $fragmentType": "PurchaseTracking_ValidCheckoutFragment";
};
export type PurchaseTracking_ValidCheckoutFragment$key = {
  readonly " $data"?: PurchaseTracking_ValidCheckoutFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PurchaseTracking_ValidCheckoutFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PurchaseTracking_ValidCheckoutFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "order",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ValidCheckout",
  "abstractKey": null
};
})();

(node as any).hash = "2855e5e21638f186f0ee7d0c619c752c";

export default node;
