import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import CaretIcon from "@/core/ui/iconsax/bold/arrow-down.svg"
import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDropdown, DiscoText, DiscoTextButton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

export type EventDestination = "community" | "product"

interface EventDestinationDropdownProps extends TestIDProps {
  form: CreateEventFormStore
}

function EventDestinationDropdown({
  form,
  testid = "EventDestinationDropdown",
}: EventDestinationDropdownProps) {
  const productLabel = useLabel("admin_experience")
  const classes = useStyles()

  const activeOrganization = useActiveOrganization()!
  const canCreateCommunityEvents = usePermissions(activeOrganization).has("events.create")

  if (!canCreateCommunityEvents) return renderText()

  return (
    <DiscoDropdown
      testid={testid}
      menuButton={({ onClick, isOpen }) => (
        <DiscoTextButton
          testid={`${testid}.button`}
          onClick={onClick}
          rightIcon={
            <CaretIcon
              className={classNames(classes.collapsibleCaret, {
                isOpen,
              })}
            />
          }
        >
          {renderText()}
        </DiscoTextButton>
      )}
    >
      {renderOptions()}
    </DiscoDropdown>
  )

  function renderText() {
    return (
      <DiscoText
        testid={`${testid}.text`}
        variant={"body-lg-600"}
        color={"groovy.grey.400"}
        marginLeft={1}
        truncateText={1}
      >
        {`/ ${renderLabel(form.state.destination)}`}
      </DiscoText>
    )
  }

  function renderLabel(destination: EventDestination) {
    switch (destination) {
      case "community":
        return "In Community"
      case "product":
        return `In ${productLabel.singular}`
    }
  }

  function renderOptions() {
    return (
      <>
        <DiscoDropdownItem
          testid={`${testid}.select.community`}
          title={renderLabel("community")}
          onClick={() => (form.state.destination = "community")}
        />
        <DiscoDropdownItem
          testid={`${testid}.select.product`}
          title={renderLabel("product")}
          onClick={() => (form.state.destination = "product")}
        />
      </>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  collapsibleCaret: {
    transition: "transform 0.2s ease-in-out",
    padding: theme.spacing(0.5),
    "& path": {
      color: theme.palette.groovy.grey[400],
    },
    "&.isOpen": {
      transform: "rotate(-90deg)",
    },
  },
}))

export default EventDestinationDropdown
