import { OrganizationCurrency } from "@/organization/edit/form/__generated__/EditOrganizationGeneralSettingsFormMutation.graphql"

const ORGANIZATION_SLUG_REGEX =
  /^(?!(dev|home|app|mastermind|staging|demo|mail|preproduction|preprod|production|www|test|testing|local|internal)$)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/
const ORGANIZATION_SLUG_LENGTH_LIMIT = {
  MIN: 2,
  MAX: 63,
}

const ORGANIZATION_HOME_FEED_POSTS_PER_PAGE = 3

const CURRENCY_LABELS: Record<OrganizationCurrency, string> = {
  usd: "United States Dollar",
  aed: "UAE Dirham",
  afn: "Afghanistan Afghani",
  all: "Albania Lek",
  amd: "Armenian Dram",
  ang: "Netherlands Antillean Guilder",
  aoa: "Angolan Kwanza",
  ars: "Argentine Peso",
  aud: "Australian Dollar",
  awg: "Aruban Florin",
  azn: "Azerbaijani Manat",
  bam: "Bosnia-Herzegovina Convertible Mark",
  bbd: "Barbadian Dollar",
  bdt: "Bangladeshi Taka",
  bgn: "Bulgarian Lev",
  bmd: "Bermudian Dollar",
  bnd: "Brunei Dollar",
  bob: "Bolivian Boliviano",
  brl: "Brazilian Real",
  bsd: "Bahamian Dollar",
  bwp: "Botswana Pula",
  byn: "Belarusian Ruble",
  bzd: "Belize Dollar",
  cad: "Canadian Dollar",
  chf: "Swiss Franc",
  cny: "Chinese Yuan Renminbi",
  cop: "Colombian Peso",
  crc: "Costa Rican Colón",
  cve: "Cape Verdean Escudo",
  czk: "Czech Koruna",
  dkk: "Danish Krone",
  dop: "Dominican Peso",
  dzd: "Algerian Dinar",
  egp: "Egyptian Pound",
  etb: "Ethiopian Birr",
  eur: "Euro",
  fjd: "Fijian Dollar",
  fkp: "Falkland Islands Pound",
  gbp: "British Pound Sterling",
  gel: "Georgian Lari",
  gip: "Gibraltar Pound",
  gmd: "Gambian Dalasi",
  hkd: "Hong Kong Dollar",
  hnl: "Honduran Lempira",
  htg: "Haitian Gourde",
  huf: "Hungarian Forint",
  idr: "Indonesian Rupiah",
  ils: "Israeli Shekel",
  inr: "Indian Rupee",
  isk: "Icelandic Króna",
  jmd: "Jamaican Dollar",
  kgs: "Kyrgyzstani Som",
  lak: "Lao Kip",
  lbp: "Lebanese Pound",
  lkr: "Sri Lankan Rupee",
  lrd: "Liberian Dollar",
  lsl: "Lesotho Loti",
  mad: "Moroccan Dirham",
  mdl: "Moldovan Leu",
  mkd: "Macedonian Denar",
  mmk: "Myanmar Kyat",
  mnt: "Mongolian Tugrik (Tögrög)",
  mop: "Macanese Pataca",
  mur: "Mauritian Rupee (Roupie)",
  mvr: "Maldivian Rufiyaa",
  mxn: "Mexican Peso",
  myr: "Malaysian Ringgit",
  mzn: "Mozambican Metical",
  nad: "Namibian Dollar",
  ngn: "Nigerian Naira",
  nio: "Nicaraguan Córdoba (Córdoba Oro)",
  nok: "Norwegian Krone",
  npr: "Nepalese Rupee (Rupiya)",
  nzd: "New Zealand Dollar",
  pab: "Panamanian Balboa",
  pen: "Peruvian Sol",
  pgk: "Papua New Guinean Kina",
  php: "Philippine Peso",
  pkr: "Pakistani Rupee (Rupiah)",
  pln: "Polish Zloty (Złoty)",
  qar: "Qatari Rial (Riyal)",
  ron: "Romanian Leu",
  rsd: "Serbian Dinar",
  rub: "Russian Ruble (Рубль)",
  sar: "Saudi Riyal (ريال سعودي)",
  sbd: "Solomon Islands Dollar",
  scr: "Seychellois Rupee (Roupie)",
  sek: "Swedish Krona",
  sgd: "Singapore Dollar",
  shp: "Saint Helena Pound",
  srd: "Surinamese Dollar",
  std: "São Tomé and Príncipe Dobra",
  szl: "Swazi Lilangeni",
  thb: "Thai Baht",
  tjs: "Tajikistani Somoni",
  top: "Tongan Pa'anga",
  try: "Turkish Lira",
  ttd: "Trinidad and Tobago Dollar",
  twd: "New Taiwan Dollar",
  uah: "Ukrainian Hryvnia",
  uyu: "Uruguayan Peso",
  uzs: "Uzbekistani Som",
  zar: "South African Rand",
  zmw: "Zambian Kwacha",
  "%future added value": "%future added value",
}

export {
  CURRENCY_LABELS,
  ORGANIZATION_HOME_FEED_POSTS_PER_PAGE,
  ORGANIZATION_SLUG_LENGTH_LIMIT,
  ORGANIZATION_SLUG_REGEX,
}
