/**
 * @generated SignedSource<<f7e61cb77f87c5ce05c20fd80b423b6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventAccessFormFields_ProductFragment$data = {
  readonly name: string;
  readonly registrationAvailability: ProductRegistrationAvailability;
  readonly type: ProductType;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupsMultiSelect_ProductFragment">;
  readonly " $fragmentType": "EventAccessFormFields_ProductFragment";
};
export type EventAccessFormFields_ProductFragment$key = {
  readonly " $data"?: EventAccessFormFields_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventAccessFormFields_ProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventAccessFormFields_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberGroupsMultiSelect_ProductFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "68fd053fd6427eeece38c46dfd1b371f";

export default node;
