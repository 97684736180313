/**
 * @generated SignedSource<<d0eccb858ec39d3b045379922aca1500>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type OrganizationMembershipsOrderBy = "bio" | "joined_on" | "member_name" | "members_list" | "recently_active" | "%future added value";
export type CommunityMembersCheckListPaginationQuery$variables = {
  after?: string | null;
  before?: string | null;
  direction?: OrderDirection | null;
  first?: number | null;
  id: string;
  last?: number | null;
  orderBy?: OrganizationMembershipsOrderBy | null;
  organizationMembershipIds?: ReadonlyArray<string> | null;
};
export type CommunityMembersCheckListPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CommunityMembersCheckList_PaginationFragment">;
  } | null;
};
export type CommunityMembersCheckListPaginationQuery = {
  response: CommunityMembersCheckListPaginationQuery$data;
  variables: CommunityMembersCheckListPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "direction"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationMembershipIds"
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v9 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v10 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v11 = {
  "kind": "Variable",
  "name": "direction",
  "variableName": "direction"
},
v12 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v13 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v14 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v15 = {
  "kind": "Variable",
  "name": "organizationMembershipIds",
  "variableName": "organizationMembershipIds"
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  {
    "kind": "Literal",
    "name": "excludeViewer",
    "value": true
  },
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunityMembersCheckListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "CommunityMembersCheckList_PaginationFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommunityMembersCheckListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v18/*: any*/),
                "concreteType": "OrganizationMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v18/*: any*/),
                "filters": [
                  "organizationMembershipIds",
                  "orderBy",
                  "direction",
                  "excludeViewer"
                ],
                "handle": "connection",
                "key": "CommunityMembersCheckList__organizationMemberships",
                "kind": "LinkedHandle",
                "name": "organizationMemberships"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71101cbe05eb3aae4adc427f942807fe",
    "id": null,
    "metadata": {},
    "name": "CommunityMembersCheckListPaginationQuery",
    "operationKind": "query",
    "text": "query CommunityMembersCheckListPaginationQuery(\n  $after: String\n  $before: String\n  $direction: OrderDirection\n  $first: Int\n  $last: Int\n  $orderBy: OrganizationMembershipsOrderBy\n  $organizationMembershipIds: [ID!]\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...CommunityMembersCheckList_PaginationFragment_3GMYxJ\n    id\n  }\n}\n\nfragment CommunityMembersCheckList_PaginationFragment_3GMYxJ on Organization {\n  organizationMemberships(organizationMembershipIds: $organizationMembershipIds, orderBy: $orderBy, direction: $direction, excludeViewer: true, first: $first, after: $after, last: $last, before: $before) {\n    edges {\n      node {\n        id\n        email\n        role\n        member {\n          id\n          ...ProfileAvatarWithDetailsFragment\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "eac967021cd1c9a55405923c4d6defbb";

export default node;
