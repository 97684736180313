import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { EditEventDrawerFormStore } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import TimeDropdown from "@components/dropdown/time/TimeDropdown"
import { generateTimeDropdownOptionFromStartTimeDate } from "@components/dropdown/time/util/timeDropdownUtils"
import TimezoneDropdown from "@components/dropdown/timezone/TimezoneDropdown"
import {
  DiscoFormControl,
  DiscoFormControlSkeleton,
  DiscoFormControlVariant,
  DiscoInputSkeleton,
} from "@disco-ui"
import DiscoDatePicker from "@disco-ui/date/DiscoDatePicker"
import DiscoTimeInput from "@disco-ui/date/DiscoTimeInput"
import { Grid } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import useISODateTimeTz from "@utils/hook/useISODateTimeTz"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { addMinutes, format, isPast } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { graphql } from "relay-runtime"

interface EventDatetimeFormFieldsProps extends TestIDProps {
  form: CreateEventFormStore | EditEventDrawerFormStore
  disabled?: boolean
  classes?: Partial<ClassNameMap<"datePicker" | "timePicker">>
}

function EventDatetimeFormFields({
  form,
  testid = "EventDatetimeFormFields",
  disabled,
  classes: customClasses,
}: EventDatetimeFormFieldsProps) {
  const {
    timezone,
    isoDateTime,
    duration,
    handleDateChange,
    handleTimeChange,
    handleDurationChange,
    handleTimezoneChange,
  } = useISODateTimeTz({
    isoDateTime: form.state.startDatetime,
    timezone: form.state.timezone || undefined,
    duration: form.state.duration,
  })
  const classes = useStyles()

  //   keep the form state synced with the component state controlled by hook
  useEffect(() => {
    if (timezone !== form.state.timezone) form.state.timezone = timezone
    if (isoDateTime !== form.state.startDatetime) {
      form.state.startDatetime = isoDateTime
      form.state.endDatetime = addMinutes(
        new Date(isoDateTime),
        form.state.duration
      ).toISOString()
    }
    if (duration !== form.state.duration) {
      form.state.endDatetime = addMinutes(new Date(isoDateTime), duration).toISOString()
    }
    const isStartTimeInPast = isPast(new Date(form.state.startDatetime))
    const isEnded = isPast(new Date(form.state.endDatetime))
    form.state.isStartTimeInPast = isStartTimeInPast
    form.state.isEnded = isEnded
    if (form.state.mode === "create") {
      if (isStartTimeInPast && !(form.state.physicalAddress || form.state.meetingUrl)) {
        // If we are creating a past event and the physcial address or meeting URL is not set
        // set the location to "TBD" so the user is not required to set a location
        // Note 1: Zoom API doesn't support creating events in the past so we don't want to allow it
        // Note 2: We cannot schedule deferred events in the past so don't allow recurring event or anyu event reminders as well
        form.state.meetingTab = "tbd"
        form.state.isRecurring = false
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone, isoDateTime, duration])

  const selectedTimezoneSubtitle = formatDateWithOptions({
    timeZone: timezone,
    format: DATE_FORMAT.UTC_OFFSET,
  })(new Date())

  return (
    <div className={classes.container}>
      <DiscoFormControl label={"Date"}>
        <DiscoDatePicker
          testid={`${testid}.start-date-select`}
          onChange={(details) => {
            if (details.value) handleDateChange(details.value)
          }}
          value={utcToZonedTime(isoDateTime, timezone)}
          // If creating, allow the start date to be in the past
          minDate={
            disabled ? undefined : form.state.mode === "create" ? undefined : new Date()
          }
          disabled={disabled}
          classes={{ root: customClasses?.datePicker }}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"Timezone"}>
        <TimezoneDropdown
          disabled={disabled}
          selectedOption={{
            value: timezone,
            title: timezone,
            context: {
              subtitle: selectedTimezoneSubtitle,
            },
          }}
          onSelect={(t) => handleTimezoneChange(t!.value)}
          testid={`${testid}.timezone-dropdown`}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"Start Time"}>
        <DiscoTimeInput
          isDisabled={disabled}
          testid={`${testid}.start-time-dropdown`}
          value={utcToZonedTime(isoDateTime, timezone)}
          onChange={handleTimeChange}
          className={customClasses?.timePicker}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"End Time"}>
        <TimeDropdown
          isDisabled={disabled}
          testid={`${testid}.end-time-dropdown`}
          selectedOption={generateTimeDropdownOptionFromStartTimeDate(
            utcToZonedTime(isoDateTime, timezone),
            duration
          )}
          startTimeString={format(
            utcToZonedTime(isoDateTime, timezone),
            DATE_FORMAT.LONG_TIME_FORMAT
          )}
          onSelect={(td) => handleDurationChange(td!.context!.differenceInMinutes)}
        />
      </DiscoFormControl>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    gap: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: 0,
    },
  },
}))

type SkeletonProps = {
  variant?: DiscoFormControlVariant
}

export const EventDatetimeFormFieldsSkeleton: React.FC<SkeletonProps> = ({
  variant = "two-column",
}) => {
  const width = variant === "two-column" ? 6 : 3
  return (
    <DiscoFormControlSkeleton
      variant={variant}
      input={
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={width}>
            <DiscoInputSkeleton />
          </Grid>
          <Grid item xs={width}>
            <DiscoInputSkeleton />
          </Grid>
          <Grid item xs={width}>
            <DiscoInputSkeleton />
          </Grid>
          <Grid item xs={width}>
            <DiscoInputSkeleton />
          </Grid>
        </Grid>
      }
    />
  )
}

export default Relay.withSkeleton<EventDatetimeFormFieldsProps & SkeletonProps>({
  component: observer(EventDatetimeFormFields),
  skeleton: EventDatetimeFormFieldsSkeleton,
})

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment EventDatetimeFormFields_Occurrence on Occurrence {
    datetimeRange
    timezone
  }
`
