/**
 * @generated SignedSource<<e03da1d68a55db67cb02f8b8927e0293>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OccurrenceAttendingButtonFragment$data = {
  readonly id: string;
  readonly product: {
    readonly id: string;
    readonly slug: string;
    readonly type: ProductType;
  };
  readonly " $fragmentType": "OccurrenceAttendingButtonFragment";
};
export type OccurrenceAttendingButtonFragment$key = {
  readonly " $data"?: OccurrenceAttendingButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceAttendingButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OccurrenceAttendingButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "f996d292b39c593d1038497a557fca82";

export default node;
