import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { DiscoButton, DiscoButtonSkeleton, DiscoDropdown } from "@disco-ui"
import DiscoButtonsGroup from "@disco-ui/button/DiscoButtonsGroup"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  form: CreateEventFormStore
}

const CreatePublishedOrDraftEventButton = ({
  form,
  testid = "CreatePublishedOrDraftEventButton",
}: Props) => {
  const classes = useStyles()

  const isPublishing = form.state.status === "published"

  return (
    <div>
      <DiscoDropdown
        testid={testid}
        shouldDisplaySpinner={form.isSubmitting}
        menuButton={({ onClick, shouldDisplaySpinner }) => (
          <DiscoButtonsGroup
            noPadding
            buttons={[
              {
                uniqueKey: "submit-button",
                content: (
                  <Form.SubmitButton
                    form={form}
                    testid={`${testid}.submit-button`}
                    shouldDisplaySpinner={shouldDisplaySpinner}
                    disabled={false}
                  >
                    {isPublishing ? "Publish" : "Save Draft"}
                  </Form.SubmitButton>
                ),
              },
              {
                uniqueKey: "dropdown-button",
                content: (
                  <DiscoButton
                    testid={`${testid}.dropdown-button`}
                    className={classes.iconButton}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      onClick(e)
                    }}
                    leftIcon={"arrow-down"}
                  />
                ),
              },
            ]}
          />
        )}
      >
        <DiscoDropdownItem
          testid={`${testid}.${isPublishing ? "save-draft" : "publish"}`}
          title={isPublishing ? "Save Draft" : "Publish"}
          onClick={() => {
            form.state.status = isPublishing ? "draft" : "published"
          }}
        />
      </DiscoDropdown>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  component: observer(CreatePublishedOrDraftEventButton),
  skeleton: () => <DiscoButtonSkeleton />,
})
