/**
 * @generated SignedSource<<a678fc0f59a84548231f38c87e6e193c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EventVisibility = "cohort" | "member_group" | "public" | "%future added value";
export type MemberGroupKind = "custom" | "default" | "role" | "test_user" | "%future added value";
export type OccurrenceReminderAudience = "all" | "non_rsvps" | "rsvps" | "%future added value";
export type OccurrenceStatus = "draft" | "published" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
export type ZoomConnectionHumanReadableUserPlanType = "basic" | "licensed" | "none" | "premium" | "unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditEventDrawerContextFragment$data = {
  readonly allRsvps: {
    readonly totalCount: number;
  } | null;
  readonly allowGuestAccess: boolean;
  readonly app: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"EventAccessFormFields_AppFragment">;
  } | null;
  readonly capacity: number | null;
  readonly collectFeedback: boolean;
  readonly content: {
    readonly attachments: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly mediaUrl: string | null;
          readonly name: string;
        };
      }>;
    };
    readonly coverPhoto: string | null;
    readonly name: string | null;
    readonly richEditorDescriptionContent: string | null;
  };
  readonly datetimeRange: ReadonlyArray<string>;
  readonly event: {
    readonly id: string;
    readonly memberGroupEntityTargets: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly memberGroup: {
            readonly id: string;
            readonly kind: MemberGroupKind;
          } | null;
        };
      }>;
    };
    readonly productId: string;
    readonly " $fragmentSpreads": FragmentRefs<"MemberGroupEventTagListFragment">;
  };
  readonly guestCapacity: number | null;
  readonly hosts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly organizationMembershipId: string;
        readonly productMembershipId: string | null;
        readonly user: {
          readonly avatar: string;
          readonly first_name: string | null;
          readonly id: string;
          readonly last_name: string | null;
        };
      };
    }>;
    readonly totalCount: number;
  };
  readonly id: string;
  readonly media: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  } | null;
  readonly meetingProvider: {
    readonly id: string;
    readonly organization: {
      readonly hasZoomIntegration: boolean;
      readonly id: string;
    };
    readonly user: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
    };
    readonly zoomConnection: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly hasWebinarAddon: boolean | null;
      readonly id: string;
      readonly isConnected: boolean;
      readonly lastName: string | null;
      readonly meetingCapacity: number | null;
      readonly picUrl: string | null;
      readonly userPlanType: ZoomConnectionHumanReadableUserPlanType;
      readonly webinarCapacity: number | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationZoomConnectionListItemFragment">;
  } | null;
  readonly meetingUrl: string | null;
  readonly physicalAddress: {
    readonly address: string;
    readonly displayAddress: string;
    readonly image: string;
    readonly lat: number;
    readonly lng: number;
    readonly placeId: string;
  } | null;
  readonly product: {
    readonly id: string;
    readonly organization: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"EventAccessFormFields_OrganizationFragment" | "EventMeetingFormFields_OrganizationFragment">;
    };
    readonly type: ProductType;
    readonly " $fragmentSpreads": FragmentRefs<"EventAccessFormFields_ProductFragment" | "EventMeetingFormFields_ProductFragment">;
  };
  readonly reminders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly audience: OccurrenceReminderAudience;
        readonly emailRichEditorBody: string;
        readonly emailSubject: string;
        readonly id: string;
        readonly sentAt: string | null;
        readonly timing: string;
      };
    }>;
  } | null;
  readonly status: OccurrenceStatus;
  readonly timezone: string;
  readonly videoRoomType: string;
  readonly visibility: EventVisibility;
  readonly " $fragmentType": "EditEventDrawerContextFragment";
};
export type EditEventDrawerContextFragment$key = {
  readonly " $data"?: EditEventDrawerContextFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditEventDrawerContextFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditEventDrawerContextFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "richEditorDescriptionContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentAttachmentNodeConnection",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentAttachmentNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentAttachment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "mediaUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverPhoto",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "OccurrenceMediaNodeConnection",
      "kind": "LinkedField",
      "name": "media",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceMediaNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OccurrenceMedia",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "media(first:1)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowGuestAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "collectFeedback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "guestCapacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EventAccessFormFields_OrganizationFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EventMeetingFormFields_OrganizationFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EventAccessFormFields_ProductFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EventMeetingFormFields_ProductFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetingUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoRoomType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MeetingProvider",
      "kind": "LinkedField",
      "name": "meetingProvider",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrganizationZoomConnectionListItemFragment"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ZoomConnection",
          "kind": "LinkedField",
          "name": "zoomConnection",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "picUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userPlanType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasWebinarAddon",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "meetingCapacity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webinarCapacity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isConnected",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasZoomIntegration",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProfileAvatarWithDetailsFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventPhysicalAddress",
      "kind": "LinkedField",
      "name": "physicalAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lng",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupEntityTargetNodeConnection",
          "kind": "LinkedField",
          "name": "memberGroupEntityTargets",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MemberGroupEntityTargetNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MemberGroupEntityTarget",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MemberGroup",
                      "kind": "LinkedField",
                      "name": "memberGroup",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MemberGroupEventTagListFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OccurrenceHostNodeConnection",
      "kind": "LinkedField",
      "name": "hosts",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceHostNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OccurrenceHost",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": "first_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": "last_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productMembershipId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "organizationMembershipId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datetimeRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EventAccessFormFields_AppFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "allRsvps",
      "args": null,
      "concreteType": "OccurrenceRSVPNodeConnection",
      "kind": "LinkedField",
      "name": "rsvps",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OccurrenceReminderNodeConnection",
      "kind": "LinkedField",
      "name": "reminders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceReminderNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OccurrenceReminder",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timing",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "audience",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emailSubject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emailRichEditorBody",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sentAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "b0b4357273f5b9abab1c104e62f22196";

export default node;
