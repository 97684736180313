/**
 * @generated SignedSource<<77e0b272bb9f58c27af290168a3ab901>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageCommunityMembersDrawerContentQuery$variables = {
  id: string;
  organizationMembershipIds: ReadonlyArray<string>;
  skipMemberships: boolean;
};
export type MessageCommunityMembersDrawerContentQuery$data = {
  readonly organization: {
    readonly organizationMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly slackUserId: string | null;
        };
      }>;
    };
    readonly viewerMembership?: {
      readonly slackUserId: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"MessageCommunityMembersSendMethodDropdownFragment" | "automationUtils_useGetDefaultSlackMessageSendAsFragment">;
  } | null;
};
export type MessageCommunityMembersDrawerContentQuery = {
  response: MessageCommunityMembersDrawerContentQuery$data;
  variables: MessageCommunityMembersDrawerContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationMembershipIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skipMemberships"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slackUserId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "condition": "skipMemberships",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationMembershipIds",
          "variableName": "organizationMembershipIds"
        }
      ],
      "concreteType": "OrganizationMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "organizationMemberships",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationMembershipNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationMembership",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageCommunityMembersDrawerContentQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "viewerMembership",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MessageCommunityMembersSendMethodDropdownFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "automationUtils_useGetDefaultSlackMessageSendAsFragment"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageCommunityMembersDrawerContentQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "viewerMembership",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "version",
                    "value": 2
                  }
                ],
                "kind": "ScalarField",
                "name": "hasSlackConnection",
                "storageKey": "hasSlackConnection(version:2)"
              },
              {
                "alias": "omscs",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "status",
                    "value": "active"
                  },
                  {
                    "kind": "Literal",
                    "name": "viewerOnly",
                    "value": true
                  }
                ],
                "concreteType": "OrganizationMembershipSlackConnectionNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMembershipSlackConnections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipSlackConnectionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembershipSlackConnection",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "organizationMembershipSlackConnections(status:\"active\",viewerOnly:true)"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b1b2bd5e8807541fa1a0d4a1674b7499",
    "id": null,
    "metadata": {},
    "name": "MessageCommunityMembersDrawerContentQuery",
    "operationKind": "query",
    "text": "query MessageCommunityMembersDrawerContentQuery(\n  $id: ID!\n  $organizationMembershipIds: [ID!]!\n  $skipMemberships: Boolean!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      viewerMembership {\n        slackUserId\n        id\n      }\n      organizationMemberships(organizationMembershipIds: $organizationMembershipIds) @skip(if: $skipMemberships) {\n        edges {\n          node {\n            id\n            slackUserId\n          }\n        }\n      }\n      ...MessageCommunityMembersSendMethodDropdownFragment\n      ...automationUtils_useGetDefaultSlackMessageSendAsFragment\n    }\n    id\n  }\n}\n\nfragment MessageCommunityMembersSendMethodDropdownFragment on Organization {\n  hasSlackConnection(version: 2)\n}\n\nfragment automationUtils_useGetDefaultSlackMessageSendAsFragment on Organization {\n  omscs: organizationMembershipSlackConnections(status: active, viewerOnly: true) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a76484fbb53d85d4f130af87ad1a6eb";

export default node;
