import { ZoomMeetingProviderDataShape } from "@/admin/integrations/zoom/connection/OrganizationZoomConnectionListItem"
import { useLabels } from "@/core/context/LabelsContext"
import EventFeedbackModal from "@/occurrence/feedback/modal/EventFeedbackModal"
import { GoogleMapAddressComponents } from "@/organization/occurrence/create-form/__generated__/CreateEventFormMutation.graphql"
import EventAccessFormFields from "@/organization/occurrence/edit-form/EventAccessFormFields"
import EventDatetimeFormFields, {
  EventDatetimeFormFieldsSkeleton,
} from "@/organization/occurrence/edit-form/EventDatetimeFormFields"
import EventMeetingFormFields, {
  EventMeetingFormFieldsSkeleton,
} from "@/organization/occurrence/edit-form/EventMeetingFormFields"
import { EventNameDestinationFormFieldsSkeleton } from "@/organization/occurrence/edit-form/EventNameDestinationFormFields"
import EventReminderFormSection from "@/organization/occurrence/edit-form/EventReminderFormSection"
import { EditEventFormFields_Occurrence$data } from "@/organization/occurrence/edit-form/__generated__/EditEventFormFields_Occurrence.graphql"
import { useEditEventDrawer } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import { EventRoomChoices } from "@/organization/occurrence/util/eventTypes"
import useUpdateEventFormZoomProviders from "@/organization/occurrence/util/useUpdateEventFormZoomProviders"
import {
  EditEventInput,
  OccurrenceReminderInput,
} from "@/organization/occurrence/__generated__/EventDrawerTitleMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoSwitch,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoNumberInput from "@disco-ui/input/DiscoNumberInput"
import { addMinutes, isPast } from "date-fns"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { graphql } from "relay-runtime"

export type EditEventFormState = Omit<
  EditEventInput,
  | "initialMemberGroupIds"
  | "description"
  | "cover"
  | "sendLiveEventPublishedEmail"
  | "publishedEmailSubject"
  | "publishedEmailRichBody"
  | "registrationAvailability"
  | "metaTitle"
  | "metaDescription"
  | "metaImageUrl"
  | "metaImageAssetId"
> &
  Omit<
    EditEventFormFields_Occurrence$data,
    | "id"
    | "status"
    | "summary"
    | "datetimeRange"
    | "event"
    | "rsvps"
    | "product"
    | "meetingProvider"
    | "coverPhoto"
    | "content"
    | "hosts"
    | " $fragmentSpreads"
    | " $fragmentType"
  > & {
    readonly mode: "edit"
    duration: number
    startDatetime: string
    endDatetime: string
    selectedMemberGroupIds: string[]
    meetingTab: "zoom" | "link" | "in-person" | "tbd"
    physicalAddress?: GoogleMapAddressComponents | null
    customLink: string
    zoomMeetingTypeRadioSelection: EventRoomChoices
    meetingProvider?: ZoomMeetingProviderDataShape
    hasCapacity: boolean
    initialMeetingProvider?: ZoomMeetingProviderDataShape
    publicAcknowledgment: boolean
    reminders: OccurrenceReminderFormState[]
    productId: GlobalID
    allowGuestAccess?: boolean
    appId?: GlobalID
    isStartTimeInPast: boolean
    isEnded: boolean
  }
export type OccurrenceReminderFormState = OccurrenceReminderInput & {
  sentAt: string | null
}

type EditEventFormProps = {
  testid?: string
}

function EditEventForm({ testid = "EditEventForm" }: EditEventFormProps) {
  const { admin_member } = useLabels()
  const [preview, setPreview] = useState(false)
  const { zoomProviders } = useUpdateEventFormZoomProviders()

  const editContext = useEditEventDrawer()
  const form = editContext.form!
  const formRef = editContext.formRef!
  const { occurrence } = editContext

  const initialMeetingProvider = occurrence.meetingProvider || undefined
  const eventVariant = occurrence.product.type === "course" ? "product" : "community"

  useEffect(() => {
    formRef.current = form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If the selected zoom account is disconnected, default to the first zoom account available
  // if there are no zoom accounts available, set the meeting provider to undefined
  useEffect(() => {
    if (!form.state.meetingProvider && zoomProviders.length) {
      form.state.meetingProvider = zoomProviders[0]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.meetingProvider])

  // When form submitted, if meeting provider is now available update the form state
  useEffect(() => {
    form.state.initialMeetingProvider = initialMeetingProvider
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMeetingProvider])

  const classes = useStyles()

  const isEnded = isPast(
    addMinutes(new Date(form.initialState.startDatetime), form.initialState.duration)
  )

  return (
    <>
      <div className={classes.container}>
        {/* Date */}
        <DiscoFormControl
          title={"Event Details"}
          variant={"one-column"}
          titleTooltip={{
            label: isEnded
              ? "Dates can't be changed on past events"
              : `Set the Date and Time for this Event`,
          }}
          errorMessages={form.errorsByField.datetimeRange}
        >
          <div className={classes.formControlChild}>
            <EventDatetimeFormFields
              form={form}
              disabled={isEnded}
              classes={{ datePicker: classes.datePicker, timePicker: classes.timePicker }}
            />
          </div>
        </DiscoFormControl>
        <DiscoDivider marginTop={0} marginBottom={2.5} />

        {/* Meeting */}
        <EventMeetingFormFields
          productKey={occurrence.product}
          organizationKey={occurrence.product.organization}
          form={form}
          eventVariant={eventVariant}
          formControlVariant={"one-column"}
        />
        <DiscoDivider marginTop={0} marginBottom={2.5} />

        {/* Capacity */}
        <DiscoFormControl
          title={"Limited Capacity"}
          variant={"one-column"}
          titleTooltip={{
            label: `Limit capacity to a set number of ${admin_member.plural}.`,
          }}
          disabled={form.state.isStartTimeInPast}
          titleAction={
            <DiscoTooltip
              content={"The event has ended"}
              disabled={!form.state.isStartTimeInPast}
            >
              <span>
                <DiscoSwitch
                  name={"capacity"}
                  hideForm
                  checked={form.state.hasCapacity}
                  onChange={handleSwitchCapacity}
                  testid={`${testid}.capacity-checkbox`}
                />
              </span>
            </DiscoTooltip>
          }
        >
          {form.state.hasCapacity && (
            <div className={classes.formControlChild}>
              <DiscoFormControl label={"Members Capacity"}>
                <DiscoNumberInput
                  value={form.state.capacity}
                  onChange={(e) => (form.state.capacity = Number(e.target.value))}
                  fullWidth
                  testid={`${testid}.Input.capacity`}
                  disabled={!form.state.hasCapacity}
                />
              </DiscoFormControl>

              {form.state.allowGuestAccess && (
                <DiscoFormControl label={"Guests Capacity"} marginBottom={0}>
                  <DiscoNumberInput
                    value={form.state.guestCapacity}
                    onChange={(e) => (form.state.guestCapacity = Number(e.target.value))}
                    fullWidth
                    testid={`${testid}.Input.guest-capacity`}
                    disabled={!form.state.hasCapacity}
                  />
                </DiscoFormControl>
              )}
            </div>
          )}
        </DiscoFormControl>

        <DiscoDivider marginTop={0} marginBottom={2.5} />

        {/* Feedback */}
        <DiscoFormControl
          title={"Feedback"}
          titleTooltip={{
            label: (
              <DiscoText
                color={"text.secondary"}
                variant={"body-xs-600"}
                display={"inline"}
              >
                {"Request feedback from participants after the event is over. "}
              </DiscoText>
            ),
            placement: "right",
          }}
          variant={"one-column"}
          disabled={form.state.isStartTimeInPast}
          titleAction={
            <DiscoTooltip
              content={"The event has ended"}
              disabled={!form.state.isStartTimeInPast}
            >
              <span>
                <DiscoSwitch
                  name={"displayFeedback"}
                  hideForm
                  checked={form.state.collectFeedback}
                  onChange={(isChecked) => (form.state.collectFeedback = isChecked)}
                  disabled={form.state.isStartTimeInPast}
                  testid={`${testid}.feedback-checkbox`}
                />
              </span>
            </DiscoTooltip>
          }
        >
          <div>
            <DiscoText color={"text.secondary"} display={"inline"}>
              <span
                onClick={() => setPreview(true)}
                role={"button"}
                tabIndex={0}
                onKeyDown={() => setPreview(true)}
                className={classes.textButton}
              >
                {"Preview feedback form"}
              </span>
            </DiscoText>
          </div>
        </DiscoFormControl>

        <DiscoDivider marginTop={0} marginBottom={2.5} />

        {/* Access */}
        <EventAccessFormFields
          form={form}
          productKey={occurrence.product}
          organizationKey={occurrence.product.organization}
          appKey={occurrence.app}
        />
        <DiscoDivider marginTop={0} marginBottom={2.5} />

        {/* Reminders */}
        <EventReminderFormSection form={form} />
      </div>

      <EventFeedbackModal
        isOpen={preview}
        onClose={() => setPreview(false)}
        occurrenceId={""}
        onSubmitFeedback={() => setPreview(false)}
        previewMode={true}
      />
    </>
  )

  function handleSwitchCapacity(isChecked: boolean) {
    form.state.hasCapacity = isChecked
    if (isChecked) return
    form.state.capacity = null
    form.state.guestCapacity = null
  }
}

const useStyles = makeUseStyles((theme) => ({
  formControlChild: {
    background: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.large,
    listStyle: "none",
    boxShadow: theme.palette.groovyDepths.insideCard,
    margin: theme.spacing(1.5, 0.25, 0),
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(2.5, 2.5, 6, 2.5),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2.5, 0, 6, 0),
    },
  },
  textButton: {
    ...theme.typography["body-md"],
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
  datePicker: {
    minWidth: "unset",
  },
  timePicker: {
    minWidth: "unset",
  },
}))

function EditEventFormSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <EventNameDestinationFormFieldsSkeleton />
      <EventDatetimeFormFieldsSkeleton />
      <EventMeetingFormFieldsSkeleton />
    </div>
  )
}

export default Relay.withSkeleton({
  component: observer(EditEventForm),
  skeleton: EditEventFormSkeleton,
})

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment EditEventFormFields_Occurrence on Occurrence {
    id
    collectFeedback
    visibility
    content {
      name
    }
    capacity
    guestCapacity
    product {
      id
      organization {
        id
      }
    }
    meetingUrl
    videoRoomType
    meetingProvider {
      ...OrganizationZoomConnectionListItemFragment
      ...OrganizationZoomConnectionListItem_meetingProviderFragment @relay(mask: false)
    }
    physicalAddress {
      displayAddress
      address
      lat
      lng
      placeId
      image
    }
    event {
      id
      productId
      memberGroupEntityTargets {
        edges {
          node {
            id
            memberGroup {
              id
              kind
            }
          }
        }
      }
    }
    hosts {
      totalCount
      edges {
        node {
          id
          user {
            id
            first_name: firstName
            last_name: lastName
            avatar
          }
        }
      }
    }
  }
`
