/**
 * @generated SignedSource<<91ffdd836ae33972115604385a1f12aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteGuestMembershipsInput = {
  guestMembershipIds: ReadonlyArray<string>;
  organizationId: string;
};
export type RemoveCommunityGuestButtonMutation$variables = {
  input: DeleteGuestMembershipsInput;
};
export type RemoveCommunityGuestButtonMutation$data = {
  readonly response: {
    readonly deletedGuestMembershipIds: ReadonlyArray<string> | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly organization: {
      readonly id: string;
    } | null;
  };
};
export type RemoveCommunityGuestButtonMutation = {
  response: RemoveCommunityGuestButtonMutation$data;
  variables: RemoveCommunityGuestButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedGuestMembershipIds",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveCommunityGuestButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteGuestMembershipsResponse",
        "kind": "LinkedField",
        "name": "deleteGuestMemberships",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveCommunityGuestButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteGuestMembershipsResponse",
        "kind": "LinkedField",
        "name": "deleteGuestMemberships",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedGuestMembershipIds"
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47199a5ae36a51a2de809668bfc0c0d5",
    "id": null,
    "metadata": {},
    "name": "RemoveCommunityGuestButtonMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveCommunityGuestButtonMutation(\n  $input: DeleteGuestMembershipsInput!\n) {\n  response: deleteGuestMemberships(input: $input) {\n    deletedGuestMembershipIds\n    organization {\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "db57b994d3f59de635a2734690ea7854";

export default node;
