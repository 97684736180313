import { useLabels } from "@/core/context/LabelsContext"
import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import EventAppSelect from "@/organization/occurrence/edit-form/EventAppSelect"
import EventProductSelect from "@/organization/occurrence/edit-form/EventProductSelect"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoFormControl,
  DiscoFormControlSkeleton,
  DiscoFormControlVariant,
  DiscoInput,
  DiscoInputSkeleton,
} from "@disco-ui"
import { observer } from "mobx-react-lite"

type Props = {
  form: CreateEventFormStore // | EditEventDrawerFormStore
  testid?: string
}
function EventNameDestinationFormFields({
  form,
  testid = "EventNameDestinationFormFields",
}: Props) {
  const { admin_experience: productLabel } = useLabels()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <DiscoFormControl
        error={Boolean(form.errorsByField.name)}
        errorMessages={form.errorsByField.name}
        title={"Event Name"}
        testid={"EventNameDestinationFormFields.form-control.name"}
      >
        <DiscoInput
          value={form.state.content!.name}
          placeholder={"Event name"}
          onChange={(e) => (form.state.content!.name = e.target.value)}
          fullWidth
          data-testid={`${testid}.Input.name`}
        />
      </DiscoFormControl>

      <DiscoFormControl
        title={renderLabel()}
        titleTooltip={{
          label: renderTooltip(),
          placement: "top",
        }}
        variant={"one-column"}
        errorMessages={
          form.state.destination === "product"
            ? form.errorsByField.productId
            : form.errorsByField.appId
        }
      >
        {renderDropdown()}
      </DiscoFormControl>
    </div>
  )

  function renderLabel() {
    switch (form.state.destination) {
      case "community":
        return "Link to App"
      case "product":
        return `Link to ${productLabel.singular}`
      default:
        return "Link to"
    }
  }

  function renderTooltip() {
    switch (form.state.destination) {
      case "community":
        return "Host this event in a community app."
      case "product":
        return `Host this event in one of your ${productLabel.plural}.`
      default:
        return "Choose where to host this event."
    }
  }

  function renderDropdown() {
    switch (form.state.destination) {
      case "community":
        return (
          <EventAppSelect
            value={form.state.appId}
            onChange={(aId) => (form.state.appId = aId)}
            testid={`${testid}.AppSelect`}
          />
        )
      case "product":
        return (
          <EventProductSelect
            value={form.state.productId}
            onChange={(pId) => (form.state.productId = pId)}
            testid={`${testid}.ProductSelect`}
          />
        )
      default:
        return null
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: 0,
    },
  },
}))

type SkeletonProps = {
  variant?: DiscoFormControlVariant
}

export const EventNameDestinationFormFieldsSkeleton: React.FC<SkeletonProps> = ({
  variant = "two-column",
}) => {
  return (
    <>
      <DiscoFormControlSkeleton variant={variant} />
      <DiscoFormControlSkeleton
        variant={variant}
        input={<DiscoInputSkeleton height={96} />}
      />
    </>
  )
}

export default Relay.withSkeleton<Props & SkeletonProps>({
  component: observer(EventNameDestinationFormFields),
  skeleton: EventNameDestinationFormFieldsSkeleton,
})
