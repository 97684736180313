/**
 * @generated SignedSource<<28162589f43e467e2e73c82cb2875f36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventAccessFormFields_OrganizationFragment$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupsMultiSelect_OrganizationFragment">;
  readonly " $fragmentType": "EventAccessFormFields_OrganizationFragment";
};
export type EventAccessFormFields_OrganizationFragment$key = {
  readonly " $data"?: EventAccessFormFields_OrganizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventAccessFormFields_OrganizationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventAccessFormFields_OrganizationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberGroupsMultiSelect_OrganizationFragment"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0d29042c705af443575b6cd06d372b6b";

export default node;
