/**
 * @generated SignedSource<<f2193db2ce4628efd6f6397eef145cc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OccurrenceWatchVideoButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"occurrenceUtils_useOccurrenceStatusFragment">;
  readonly " $fragmentType": "OccurrenceWatchVideoButtonFragment";
};
export type OccurrenceWatchVideoButtonFragment$key = {
  readonly " $data"?: OccurrenceWatchVideoButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceWatchVideoButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OccurrenceWatchVideoButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "occurrenceUtils_useOccurrenceStatusFragment"
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};

(node as any).hash = "9bc1c8be4f14cf2cce6faa860f5c5c8b";

export default node;
