import { ContentFormState } from "@/content/form/util/contentFormUtil"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import { CreateOccurrenceMediaInput } from "@/occurrence/live-box/__generated__/UploadEventVideoModalMutation.graphql"
import { EditEventFormState } from "@/organization/occurrence/edit-form/EditEventForm"
import { EditEventDrawerContextCreateMediaMutation } from "@/organization/occurrence/event-drawer/__generated__/EditEventDrawerContextCreateMediaMutation.graphql"
import {
  EditEventDrawerContextFragment$data,
  EditEventDrawerContextFragment$key,
} from "@/organization/occurrence/event-drawer/__generated__/EditEventDrawerContextFragment.graphql"
import { EditEventDrawerContextMutation } from "@/organization/occurrence/event-drawer/__generated__/EditEventDrawerContextMutation.graphql"
import { EditEventDrawerContextQuery } from "@/organization/occurrence/event-drawer/__generated__/EditEventDrawerContextQuery.graphql"
import { EventRoomChoices } from "@/organization/occurrence/util/eventTypes"
import useUpdateEventFormZoomProviders, {
  SupportedForms,
} from "@/organization/occurrence/util/useUpdateEventFormZoomProviders"
import { EditEventInput } from "@/organization/occurrence/__generated__/EventDrawerTitleMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import EditorUtils from "@components/editor/EditorUtils"
import usePermissions from "@utils/hook/usePermissions"
import { convertGolangDurationToDays } from "@utils/time/timeUtils"
import { differenceInMinutes, isPast } from "date-fns"
import { observer } from "mobx-react-lite"
import React, { useContext, useEffect } from "react"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

export type EditEventDrawerContextValue = {
  form?: EditEventDrawerFormStore
  createRecordingForm?: CreateEventRecordingFormStore
  formRef?: React.MutableRefObject<SupportedForms | null>
  occurrence: EditEventDrawerContextFragment$data
}

const EditEventDrawerContext = React.createContext<EditEventDrawerContextValue>(
  {} as EditEventDrawerContextValue
)

export function useEditEventDrawer() {
  return useContext(EditEventDrawerContext)
}

type EditEventDrawerFormState = Pick<EditEventInput, "occurrenceId"> &
  ContentFormState &
  EditEventFormState

export type EditEventDrawerFormStore = FormStore<
  EditEventDrawerFormState,
  EditEventDrawerContextMutation
>

type CreateEventRecordingFormState = CreateOccurrenceMediaInput
export type CreateEventRecordingFormStore = FormStore<
  CreateEventRecordingFormState,
  EditEventDrawerContextCreateMediaMutation
>

interface EditEventDrawerContextProviderProps {
  occurrenceId: GlobalID
}

const EditEventDrawerContextProvider: React.FC<EditEventDrawerContextProviderProps> = ({
  children,
  occurrenceId,
}) => {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()!
  const includeForm = usePermissions(activeProduct).has("events.manage")

  const { occurrence: occurrenceKey } = useLazyLoadQuery<EditEventDrawerContextQuery>(
    graphql`
      query EditEventDrawerContextQuery($id: ID!) {
        occurrence: node(id: $id) {
          ... on Occurrence {
            ...EditEventDrawerContextFragment
          }
        }
      }
    `,
    {
      id: occurrenceId,
    },
    {
      // Only fetch the data if the user has permission to edit the lesson
      fetchPolicy: includeForm ? "store-and-network" : "store-only",
    }
  )

  const occurrence = useFragment<EditEventDrawerContextFragment$key>(
    graphql`
      fragment EditEventDrawerContextFragment on Occurrence {
        id
        # Details
        content {
          name
          richEditorDescriptionContent
          attachments {
            edges {
              node {
                id
                name
                mediaUrl
              }
            }
          }
          coverPhoto
        }
        # Recording
        media(first: 1) {
          edges {
            node {
              id
            }
          }
        }
        allowGuestAccess
        # Settings
        status
        ...EditEventFormFields_Occurrence @relay(mask: false)
        ...EventDatetimeFormFields_Occurrence @relay(mask: false)
        ...EventHostsFormFields_Occurrence @relay(mask: false)
        product {
          id
          type
          ...EventAccessFormFields_ProductFragment
          ...EventMeetingFormFields_ProductFragment
          organization {
            id
            ...EventAccessFormFields_OrganizationFragment
            ...EventMeetingFormFields_OrganizationFragment
          }
        }
        app {
          id
          ...EventAccessFormFields_AppFragment
        }
        allRsvps: rsvps {
          totalCount
        }
        reminders {
          edges {
            node {
              id
              timing
              audience
              emailSubject
              emailRichEditorBody
              sentAt
            }
          }
        }
        event {
          ...MemberGroupEventTagListFragment
        }
      }
    `,
    occurrenceKey!
  )

  const { datetimeRange, event } = occurrence
  const { zoomProviders, formRef } = useUpdateEventFormZoomProviders()

  const isCustomLink = occurrence.videoRoomType === "custom-link"
  const isInPerson = occurrence.videoRoomType === "in-person"
  const isZoom =
    occurrence.videoRoomType === "group" || occurrence.videoRoomType === "broadcast"
  const hosts = Relay.connectionToArray(occurrence.hosts).map((h) => ({
    ...(h.productMembershipId && { productMembershipId: h.productMembershipId }),
    ...(h.organizationMembershipId && {
      organizationMembershipId: h.organizationMembershipId,
    }),
  }))
  const initialMemberGroupIds = Relay.connectionToArray(event.memberGroupEntityTargets)
    .filter((mget) =>
      occurrence.product.type === "course" ? mget.memberGroup?.kind === "custom" : true
    )
    .map((mget) => mget.memberGroup!.id)

  const initialMeetingProvider = occurrence.meetingProvider || undefined
  const meetingProvider = isCustomLink
    ? zoomProviders[0] || undefined
    : initialMeetingProvider || undefined

  const hasRecording = Boolean(Relay.connectionToArray(occurrence.media)[0])
  const eventDrawer = useGlobalDrawer("event")

  const form = useFormStore<EditEventDrawerContextMutation, EditEventDrawerFormState>(
    graphql`
      mutation EditEventDrawerContextMutation($input: EditEventInput!) {
        response: editEvent(input: $input) {
          node {
            id
            coverPhoto
            name
            richEditorDescriptionContent
            ...EditEventDrawerContextFragment @relay(mask: false)
            ...OccurrenceListItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      // Details
      occurrenceId,
      content: {
        richEditorDescriptionContent: EditorUtils.getInitialEditorState(
          occurrence?.content?.richEditorDescriptionContent
        ),
        attachments: Relay.connectionToArray(occurrence?.content?.attachments),
        coverPhoto: occurrence?.content?.coverPhoto,
      },

      // Settings
      mode: "edit",
      collectFeedback: occurrence.collectFeedback,
      timezone: occurrence.timezone,
      notificationEmailBody: `The event ${occurrence.content?.name} has been updated.`,
      notifyMembersOnChange: false,
      hasCapacity: Boolean(occurrence.capacity) || Boolean(occurrence.guestCapacity),
      capacity: occurrence.capacity,
      guestCapacity: occurrence.guestCapacity,
      // flatten the datetimeRange to detect changed values
      startDatetime: datetimeRange[0],
      endDatetime: datetimeRange[1],
      duration: differenceInMinutes(
        new Date(datetimeRange[1]),
        new Date(datetimeRange[0])
      ),
      // groups
      visibility: occurrence.visibility,
      selectedMemberGroupIds: initialMemberGroupIds,
      // video settings
      meetingTab: isCustomLink
        ? "link"
        : isInPerson
        ? "in-person"
        : isZoom
        ? "zoom"
        : "tbd",
      meetingProvider,
      initialMeetingProvider,
      meetingUrl: occurrence.meetingUrl,
      videoRoomType: occurrence.videoRoomType,
      physicalAddress: occurrence.physicalAddress,
      customLink: isCustomLink && occurrence.meetingUrl ? occurrence.meetingUrl : "",
      zoomMeetingTypeRadioSelection:
        isCustomLink || isInPerson
          ? "group" // by default, use group zoom setting
          : (occurrence.videoRoomType as EventRoomChoices),
      hosts,
      publicAcknowledgment: occurrence.visibility === "public",
      reminders: Relay.connectionToArray(occurrence.reminders).map((r) => ({
        id: r.id,
        timing: r.timing && convertGolangDurationToDays(r.timing),
        audience: r.audience,
        emailSubject: r.emailSubject,
        emailRichEditorBody: r.emailRichEditorBody,
        sentAt: r.sentAt,
      })),
      productId: occurrence.product.id,
      allowGuestAccess: occurrence.allowGuestAccess,
      appId: occurrence.app?.id,
      isStartTimeInPast: isPast(new Date(datetimeRange[0])),
      isEnded: isPast(new Date(datetimeRange[1])),
    }
  )

  const createRecordingForm = useFormStore<
    EditEventDrawerContextCreateMediaMutation,
    CreateEventRecordingFormState
  >(
    graphql`
      mutation EditEventDrawerContextCreateMediaMutation(
        $input: CreateOccurrenceMediaInput!
      ) {
        response: createOccurrenceMedia(input: $input) {
          node {
            id
            kind
            url
            videoPoster
            occurrence {
              ...EventDrawerRecordingSectionFragment
              ...occurrenceUtils_useOccurrenceStatusFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      occurrenceId,
      kind: "video",
      url: "",
      sendNotificationEmail: false,
      notificationEmailBody: "",
      mediaAssetId: hasRecording
        ? undefined
        : eventDrawer.params.temporaryRecordingAssetId,
      notificationEmailRecipients: undefined,
      organizationId: activeOrganization.id,
    }
  )

  const { setUnsavedChanges } = useUnsavedChangesModalContext()

  useEffect(() => {
    setUnsavedChanges(form.isChanged)
  }, [form.isChanged, setUnsavedChanges])

  return (
    <EditEventDrawerContext.Provider
      value={{
        form: includeForm ? form : undefined,
        createRecordingForm: includeForm ? createRecordingForm : undefined,
        formRef,
        occurrence,
      }}
    >
      {children}
    </EditEventDrawerContext.Provider>
  )
}

// No skeleton to fallback onto drawer skeleton when suspending
export default observer(EditEventDrawerContextProvider)
